import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'

const ImageWrapper = styled.div`
  max-width: 300px;
  padding: 0;
  margin: 0 auto;
`

const Logo = props => (
  <StaticQuery
    query={graphql`
      query {
        logo: file(
          relativePath: { eq: "logo.png" }) {
          childImageSharp {
            fluid(maxWidth: 300) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }`
    }
    render={ data => (
      <ImageWrapper>
        <Img
          loading="eager"
          fadeIn={true}
          fluid={data.logo.childImageSharp.fluid} />
      </ImageWrapper>
    )} />
)

export {
  Logo
}
