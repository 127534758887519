import React from 'react'
import get from 'lodash/get'
import { graphql, StaticQuery } from 'gatsby'

import { ItemGrid, Item } from '../common'
import { BlockWrapper } from '../containers'

export const pageQuery = graphql`
  query CategoryBlock {
    allContentfulCategory(
      filter: {
        contentfulparent: { contentful_id: { eq: null }}
        active: { eq: true }
      }
      sort: {fields:[order], order:ASC}) {
      edges {
        node {
          name
          slug
          image {
            fluid(maxHeight: 300) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  }
`

const renderSections = categories => {
  if (!categories) {
    return null
  }
  return categories.map(({ node }) => {
    let url = `/${ node.slug }/`
    return (
      <Item
        category={node.slug}
        desktopWidth="25%"
        desktopHeight="500px"
        key={url}
        url={url}
        title={node.name}
        image={node.image}
      />
    )
  })
}

const CategoryBlock = props => (
  <StaticQuery
    query={pageQuery}
    render={data => {
      const categories = get(data, 'allContentfulCategory.edges', [])
        .filter(({ node }) => node.image)
      return (
        <BlockWrapper>
          <ItemGrid>
            { renderSections(categories) }
          </ItemGrid>
        </BlockWrapper>
      )
    }} />
)

export {
  CategoryBlock
}
