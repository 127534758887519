import React from 'react'
import get from 'lodash/get'
import { graphql, Link, StaticQuery } from 'gatsby'
import styled from 'styled-components'
import Img from 'gatsby-image'

import { BlockWrapper } from '../containers'

const pageQuery = graphql`
  query InstagramBlockQuery {
    allContentfulInstagramPost(
      sort: {fields:[publishedDate], order:DESC}
    ) {
      edges {
        node {
          id
          url
          image {
            fluid(maxHeight: 300) {
             ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  }
`

const InstagramContainer = styled(props => <BlockWrapper {...props} />)`
  position: relative;
`

const InstagramBackground = styled.div`
  height: 300px;

  ${ props => props.theme.media.phone`
    height: 400px;
  ` }
`

const InstagramButton = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;

  a {
    background-color: rgba(255, 255, 255, 0.9);
    padding: 10px 40px;
    font-size: 1.2rem;
    text-transform: uppercase;
    border: none;
    text-decoration: none;
    letter-spacing: 2px;
    color: ${ props => props.theme.black };
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
    transition: all 0.25s;
    border-radius: 2px;

    &:hover {
      transform: scale(1.01);
      transition: all 0.25s;
    }
  }
`

export const InstagramGrid = styled.div`
  display: flex;
  flex-flow: wrap;
  padding: 10px 0 0 0;
  justify-content: space-between;
  align-items: center;
  height: 300px;
  overflow: hidden;

  ${ props => props.theme.media.phone`
    height: 400px;
  ` }
`

export const InstagramImage = styled.div`
  width: 25%;
  align-items: center;
  justify-content: center;
  height: 300px;

  & > div {
    margin: 0 2px;
    height: 100%;
  }

  ${ props => props.theme.media.phone`
    width: 50%;
    height: 200px;
      & > div {
        margin: 2px;
        height: 100%;
      }
  ` }
`

const renderPosts = posts => {
  if (posts.length === 0) {
    return null
  }
  return posts
    .slice(0, 4).map(({ node }) => (
      <InstagramImage key={get(node, 'image.fluid.src')}>
        <Img fluid={get(node, 'image.fluid')} />
      </InstagramImage>
    ))
}

const InstagramBlock = props => (
  <StaticQuery
    query={pageQuery}
    render={data => {
      const posts = get(data, 'allContentfulInstagramPost.edges', [])
        .filter(({ node }) => node.image)
      return (
        <InstagramContainer>
          <InstagramBackground>
            <InstagramGrid>
              { renderPosts(posts) }
            </InstagramGrid>
          </InstagramBackground>
          <InstagramButton>
            <Link to='/instagram/'>Shop my Instagram</Link>
          </InstagramButton>
        </InstagramContainer>
      )
    }} />
)

export {
  InstagramBlock
}
