import styled from 'styled-components'

export const BoringText = styled.div`
  font-family: ${ props => props.theme.bodyFont };
  font-weight: ${ props => props.theme.bodyWeight };
  font-size: ${ props => props.theme.bodySize };
  padding: 10px 0;

  a {
    opacity: 1;
    transition: all 0.25s;

    &:hover {
      opacity: 0.9;
      transition: all 0.25s;
    }
  }

  img {
    max-width: 300px;
    margin: 0 auto;
    height: auto;
    display: block;
  }
`

export const PrettyText = styled.h1`
  font-family: ${ props => props.theme.headerFont };
  font-size: 1.5rem;
  line-height: 1.75rem;
  letter-spacing: 3px;
  text-transform: uppercase;
  text-align: center;
  margin: 0;
`
