import React from 'react'
import { Seo } from '../components'

import { SplashBlock } from '../components/home/splash-block'
import { SubscribeBlock } from '../components/home/subscribe-block'
import { AboutBlock } from '../components/home/about-block'
import { CategoryBlock } from '../components/home/category-block'
import { InstagramBlock } from '../components/home/instagram-block'
import { Navigation } from '../components/navigation'

const HomePage = props => {
  const title = 'Chrissy Horton'
  return (
    <div>
      <Seo title={title} location={props.location} />
      <SplashBlock />
      <div style={{ position: 'relative' }}>
        <Navigation />
        {/* <MostRecent /> */}
        <AboutBlock />
        <SubscribeBlock />
        <CategoryBlock />
        {/*
        <InstagramBlock />
        */}
      </div>
    </div>
  )
}

export default HomePage
