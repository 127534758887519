import React from 'react'
import { MailChimpSubscribe } from '../../components/mailchimp'

const SubscribeBlock = props => (
  <MailChimpSubscribe />
)

export {
  SubscribeBlock
}
